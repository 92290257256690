import React, { useEffect, useState } from 'react';
import './App.css';
import { Login } from './containers/login/Login';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Dashboard } from './containers/dashboard/DashBoard';
import Header from './containers/common/Header';
import { CustomizedSnackbars } from './containers/common/CustomizedSnackbars';
import { Box, CircularProgress } from '@mui/material';
import { User } from './containers/component/User';
import ViewApiUser from './containers/component/ViewApiUser';
import AvailableDbTable from './containers/component/AvailableDbTable';

const App = () => {

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>()
  const [loggedInUser, setLoggedInUser] = useState({});
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState<string>("success");
  const [message, setMessage] = React.useState<string>("success");
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      const userInfo = localStorage.getItem('userInfo');
      if (userInfo)
        setLoggedInUser(JSON.parse(userInfo));
    } else {
      setIsAuthenticated(false);
      setLoggedInUser({});
    }
    setLoading(false);
  }, []);

  const handleSnackBar = (open: boolean, severity: string, message: string) => {
    setOpen(open);
    setSeverity(severity);
    setMessage(message);
  }
  const handleLogout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
    setLoggedInUser({});
  };
  let router = (
    <Router>
      {isAuthenticated && (
        <Header handleLogout={handleLogout} user={loggedInUser} />
      )}
      <Routes>
        <Route path="/" element={<Login handleClick={setIsAuthenticated} setLoggedInUser={setLoggedInUser} handleSnackBar={handleSnackBar} />} />
        <Route path="*" element={<Navigate replace to="/" />} />
        <Route path="/dashboard" element={isAuthenticated ? <Dashboard user={loggedInUser} handleSnackBar={handleSnackBar} /> : <Navigate to="/" />} />
        <Route path="/api_user/:id" element={isAuthenticated ? <User user={loggedInUser} handleSnackBar={handleSnackBar}/> : <Navigate to="/" />} />
        <Route path="/api_user" element={isAuthenticated ? <User user={loggedInUser} handleSnackBar={handleSnackBar}/> : <Navigate to="/" />} />
        <Route path="/users" element={isAuthenticated ? <ViewApiUser user={loggedInUser} handleSnackBar={handleSnackBar}/> : <Navigate to="/" />} />
        <Route path="/tables" element={isAuthenticated ? <AvailableDbTable user={loggedInUser} handleSnackBar={handleSnackBar}/> : <Navigate to="/" />} />
      </Routes>
    </Router>)

  if (loading) {
    return <div> <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' , padding: 20}}>
    <CircularProgress />
  </Box></div>; // Or a loading spinner
  }

  return (
    <div className="App">
      <CustomizedSnackbars open={open} severity={severity} message={message} setOpen={setOpen} />
      {router}
    </div>
  );
}

export default App;
