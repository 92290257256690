import React, { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, IconButton, Paper, TextField, Button, Theme, Tooltip, Typography, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FileCopy, PlayArrow } from '@mui/icons-material';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: '#e0f7fa',
        color: '#004d40',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        overflow: 'hidden',
    },
    browserHeader: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#80deea',
        padding: theme.spacing(1),
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
    },
    addressBar: {
        flex: 1,
        backgroundColor: '#e0f7fa',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0, 1),
        color: '#004d40',
        fontFamily: 'monospace',
    },
    code: {
        fontFamily: 'monospace',
        whiteSpace: 'pre-wrap',
        backgroundColor: '#e0f7fa',
        color: '#004d40',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        marginTop: theme.spacing(2),
    },
    buttonGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    iconButton: {
        color: '#004d40',
    },
    response: {
        marginTop: theme.spacing(2),
        backgroundColor: '#80deea',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
    },
    responseContent: {
        maxHeight: '200px',
        overflow: 'auto',
        whiteSpace: 'pre-wrap',
        textAlign: 'left',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#4dd0e1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#00acc1',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#00838f',
        },
    },
    copyButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: '#004d40',
    },
    section: {
        marginBottom: theme.spacing(2),
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
    },
    textField: {
        width: '100%',
        backgroundColor: '#e0f7fa',
        borderRadius: theme.shape.borderRadius,
        marginTop: theme.spacing(2),
    },
}));

export const OutputTab = (props: any) => {
    const classes = useStyles();
    const [response, setResponse] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>('2024-04-23'); // Default start date
    const [endDate, setEndDate] = useState<string>('2024-04-24');     // Default end date
    const [error, setError] = useState<string>('');
    const tableName = props.selectedTable?.tableCode;
    const apiBaseUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/table_data?startDate=${startDate}&endDate=${endDate}&format=JSON&tableName=${tableName}`;
    const apiKey = props.credential.apiKey;
    const secretKey = props.credential.secretKey;
    const curlCommand = `curl --location '${apiBaseUrl}' \
    --header 'x-api-key: ${apiKey}' \
    --header 'x-secret-key: ${secretKey}'`;

    useEffect(() => {
        validateDates();
    }, [startDate, endDate])

    const validateDates = () => {
        setError('')
        const start = dayjs(startDate);
        const end = dayjs(endDate);

        if (!start.isValid() || !end.isValid()) {
            setError('Invalid date format. Please use YYYY-MM-DD.');
        }

        if (start.isAfter(end)) {
            setError('Start date cannot be later than end date.');
        }

        if (end.diff(start, 'day') > 2) {
            setError('Date range cannot exceed 2 days.');
        }
    };

    const handleRun = () => {
        if (error)
            return;
        setLoading(true);
        fetch(apiBaseUrl, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-api-key": apiKey,
                "x-secret-key": secretKey
            },
            method: "GET"
        })
            .then((response) => {
                if (!response.ok) {
                    setResponse('Network response was not ok');
                }
                return response.json();
            }).then(data => {
                setResponse(JSON.stringify(data, null, 2));
            }).catch(error => {
                setResponse(error.toString());
            }).finally(() => {
                setLoading(false);
            });
    };

    return (
        <Box sx={{ paddingRight: 5 }}>
            <Paper className={classes.root}>
                <Box className={classes.section}>
                    <Typography variant="h6">CURL Command</Typography>
                    <Box className={classes.browserHeader}>
                        <Box className={classes.addressBar}>
                            {curlCommand}
                        </Box>
                        <Box className={classes.copyButton}>
                            <CopyToClipboard text={curlCommand}>
                                <Tooltip title="Copy" arrow>
                                    <IconButton disabled={error !== ''}  className={classes.iconButton}>
                                        <FileCopy />
                                    </IconButton>
                                </Tooltip>
                            </CopyToClipboard>
                            <Tooltip title="Run" arrow>
                                <IconButton disabled={error !== ''} className={classes.iconButton} onClick={handleRun}>
                                    <PlayArrow />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>

                {/* Date input fields */}
                <Box sx={{ display: 'flex', gap: 2, marginTop: 2, marginBottom: 2 }}>
                    <TextField
                        error={error !== ''}
                        label="Start Date"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <TextField
                        error={error !== ''}
                        label="End Date"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    { (error === '') ?
                        <Alert severity='info'>You can change date from here</Alert> : (
                            <Alert variant='standard' severity="error">
                                <Typography align='center' variant='caption'>
                                    {error}
                                </Typography>
                            </Alert>
                        )}
                </Box>



                {loading && (<Box className={classes.loader}>
                    <CircularProgress color="inherit" />
                </Box>)}
                {response && (
                    <Box className={classes.response}>
                        <Typography variant="h6">Response</Typography>
                        <Box className={classes.responseContent}>
                            <Typography component="pre" className={classes.code}>
                                {response}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Paper>
        </Box>
    );
};
