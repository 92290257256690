import axios from 'axios';
import { createBrowserHistory, History } from 'history';

export const history: History = createBrowserHistory();

export const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + '/ui/v1',
});

// Add a response interceptor
api.interceptors.response.use(
    (response: any) => {
        const token = response?.data?.data?.token;
        if(token)
            localStorage.setItem('token', token);
        return response;
    },
    (error: any) => {
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.reload();
        }        
        return Promise.reject(error)
    }
);

// Add a request interceptor
api.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = token;
            config.headers['Content-Type'] ='application/json';
        }
        return config;
    },
    (error: any) => Promise.reject(error)
);

export default api