import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';



export const CustomizedSnackbars = (props: any) => {

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
  
      props.setOpen(false);
    };
    
    
    return (
      <div>
        <Snackbar
          open={props.open}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={handleClose}
        >
            <Alert variant="filled" onClose={handleClose} severity={props.severity}>
                {props.message}
            </Alert>
        </Snackbar>
      </div>
    );
  }