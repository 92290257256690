import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { getUserList } from '../api/Api';
import { Download, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ViewApiUser = (props: any) => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState<any>([]);

  const handleEdit = (id: any) => {
    navigate(`/api_user/${id}`);
  }
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 10 },
    { field: 'userName', headerName: 'User name', flex: 15 },
    { field: 'email', headerName: 'Email', flex: 18 },
    { field: 'maxLimit', headerName: 'Max Limit', flex: 10 },
    { field: 'tableCode', headerName: 'Table Code', flex: 20 },
    { field: 'blocked', headerName: 'Blocked', flex: 10 },
    { field: 'apiKey', headerName: 'Api Key', flex: 25 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 10,
      disableExport: true,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              onClick={() => handleEdit(params.row.id)}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }
  ];

  useEffect(() => {
    getUserList().then(response => {
      const data = response.data
      const users: any = []
      data.forEach((element: any) => {
        let tables = ""
        element.tableDetails.forEach((name: any) => tables = tables.concat(name.tableCode + " "))
        users.push({
          id: element.id,
          userName: element.userName,
          email: element.email,
          maxLimit: element.maxLimit,
          apiKey: element.apiKey,
          tableCode: tables,
          blocked: element.blocked
        })
      });
      setUserList(users);
    }).catch(error => {
      console.log(error);
      // props.handleSnackBar(true, "error", error.response.data.message)
    })
  }, [props]);

  return (
    <Box sx={{ ml:4, mr:4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'end', padding: 3 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="contained" onClick={() => navigate(`/tables`)}>Database tables</Button>
          <Button variant="contained" onClick={() => navigate(`/api_user`)}>Add New</Button>
        </Box>
      </Box>
      <DataGrid 
        rows={userList}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { printOptions: { disableToolbarButton: true }, showQuickFilter: true } }}
        disableDensitySelector
        disableColumnFilter
        disableColumnMenu
      />
    </Box>
  );
}

export default ViewApiUser;
