import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';

const Header = (props: any) => {
    const navigate = useNavigate();

    // Handle logout function
    const onLogout = () => {
        props.handleLogout();
        navigate('/');
    };

    return (
        <AppBar position="static">
            <Toolbar>
                {/* Navigation links */}
                <Box sx={{ flexGrow: 1 }}>
                </Box>

                {props.user && (
                    <Typography sx={{ mr: 2 }}>
                        Welcome, {props.user.firstName}
                    </Typography>
                )}
                <Button color="inherit" onClick={onLogout}>
                    Logout
                </Button>
            </Toolbar>
        </AppBar>
    );
}

export default Header;