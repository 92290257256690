import React, { useEffect, useState } from 'react';
import { AddUser, TableOptionType } from './AddUser';
import { OutputTab } from './OutputTab';
import { Box, Button, Grid, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getUserById } from '../api/Api';

export const User = (props: any) => {
    const { id } = useParams();
    const defaultTheme = createTheme();
    const [userData, setUserData] = useState();
    const [credential, setCredential] = useState<object>({});
    const [selectedTable, setSelectedTable] = useState<TableOptionType>();
    useEffect(() => {
        if (id && userData === undefined) {
            getUserById(id).then(response => {
                handleSave(response.data);
            });
        }
    }, [id, userData]);

    const handleSave = (data: any) => {
        setUserData(data);
        setCredential({
            apiKey: data.apiKey,
            secretKey: data.secretKey
        })
        const table = data.tableDetails[0];
        setSelectedTable(table);
    }

    return <ThemeProvider theme={defaultTheme}>
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 3, ml: 6 }}>
                <Button variant="outlined" onClick={() => window.history.back()}>Back</Button>
            </Box>
            <Box sx={{ marginTop: 3, marginBottom: 3 }}>
                {id ?
                    <Typography component="h1" variant="h5" sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>Edit User Detail</Typography> :
                    <Typography component="h1" variant="h5" sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>Create New User</Typography>}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AddUser
                            handleSnackBar={props.handleSnackBar}
                            credential={credential}
                            setCredential={setCredential}
                            initialUserData={userData}
                            handleSave={handleSave}
                            selectedTable={selectedTable}
                            setSelectedTable={setSelectedTable} />
                    </Grid>
                    <Grid item xs={6}>
                        <OutputTab credential={credential}
                            setCredential={setCredential}
                            selectedTable={selectedTable}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>

    </ThemeProvider>
}