import React, { ChangeEvent, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Autocomplete, IconButton, InputAdornment, Tooltip, Typography } from '@mui/material';
import { createNewTable, getTableList, creeteUser } from '../api/Api';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FileCopy } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';

export interface TableOptionType {
    id: number;
    tableName: string;
    tableCode: string;
}

const initialErrors = {
    userName: {
        error: false,
        errorMessage: 'username is required'
    },
    email: {
        error: false,
        errorMessage: 'email is required'
    },
    maxLimit: {
        error: false,
        errorMessage: 'Max limit is required'
    },
    tableName: {
        error: false,
        errorMessage: 'Please select table name'
    }
}

export const AddUser = (props: any) => {
    const defaultTheme = createTheme();
    const { credential, handleSave, initialUserData, selectedTable, setSelectedTable } = props;
    const [displayTableForm, setDisplayTableForm] = useState<boolean>(false);
    const [newTableName, setNewTableName] = useState("");
    const [tableList, setTableList] = useState<TableOptionType[]>([]);
    const [errorValue, setErrorValue] = useState(initialErrors);
    const [userData, setUserData] = useState({
        userName: initialUserData?.userName || "",
        email: initialUserData?.email || "",
        maxLimit: initialUserData?.maxLimit || 20,
        tableName: selectedTable?.tableCode,
    });

    useEffect(() => {
        if (tableList.length === 0)
            listOfTable();
        if (initialUserData && userData.email === "") {
            setUserData({
                userName: initialUserData?.userName,
                email: initialUserData?.email,
                maxLimit: initialUserData?.maxLimit,
                tableName: selectedTable?.tableCode,
            })
        }
    }, [initialUserData, userData]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const formData = {
            id: initialUserData?.id || null,
            email: data.get('email')?.toString() || "",
            userName: data.get('userName')?.toString() || "",
            maxLimit: Number.parseInt(data.get('maxLimit')?.toString() || "20"),
            tableName: selectedTable?.tableName || "",
        };
        let valid = true;
        const newErrors = { ...initialErrors };

        if (!formData.tableName) {
            newErrors.tableName.error = true;
            valid = false;
        }
        if (!formData.userName) {
            newErrors.userName.error = true;
            valid = false;
        }
        if (!formData.email) {
            newErrors.email.error = true;
            valid = false;
        }
        if (formData.maxLimit < 0 || formData.maxLimit > 500) {
            newErrors.maxLimit.error = true;
            newErrors.maxLimit.errorMessage = "Please set proper limit between 1 to 500";
            valid = false;
        }

        setErrorValue(newErrors);
        if (!valid) return;

        creeteUser(formData).then(response => {
            handleSave(response.data.data);
            props.handleSnackBar(true, "success", response.data.message);
        }).catch(error => {
            props.handleSnackBar(true, "error", error?.response?.data?.message);
        });
    };

    const handleAutocompleteChange = (event: any, value: TableOptionType | null) => {
        setSelectedTable(value);
    };


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserData((prevState: any) => ({
            ...prevState,
            [name]: name === 'limit' ? parseInt(value) : value
        }));
    };

    const saveNewTable = () => {
        createNewTable(newTableName).then(response => {
            props.handleSnackBar(true, "success", response.data.message);
            listOfTable();
            setDisplayTableForm(false);
        }).catch(error => {
            props.handleSnackBar(true, "error", error.response.data.message);
        });
    }

    const listOfTable = () => {
        getTableList().then(response => {
            setTableList(response.data);
        }).catch(error => {
            props.handleSnackBar(true, "error", error.response.data.message);
        });
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Grid>
                    {!displayTableForm ? (
                        <Box>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={tableList}
                                    getOptionLabel={(option) => option.tableCode}
                                    id="auto-complete"
                                    fullWidth
                                    autoComplete
                                    includeInputInList
                                    value={selectedTable || null}
                                    onChange={handleAutocompleteChange}
                                    isOptionEqualToValue={(option, value) => option.tableCode === value.tableCode}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label="Select Table"
                                        error={errorValue.tableName.error}
                                        helperText={errorValue.tableName.error && errorValue.tableName.errorMessage} />}
                                />
                            </Grid>
                            <Typography variant="caption" color="warning">
                                Click on Add New table only if you do not find the table name in the dropdown
                            </Typography>
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={() => setDisplayTableForm(!displayTableForm)}
                            >
                                Add New Table
                            </Button>
                        </Box>
                    ) : (
                        <Box>
                            <Grid item xs={12}>
                                <TextField
                                    name="tableName"
                                    required
                                    fullWidth
                                    id="tableName"
                                    label="Table Name"
                                    autoFocus
                                    onChange={e => setNewTableName(e.target.value)}
                                    error={errorValue.tableName.error}
                                    helperText={errorValue.tableName.error && errorValue.tableName.errorMessage}
                                />
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        fullWidth
                                        onClick={() => {
                                            setDisplayTableForm(!displayTableForm);
                                            listOfTable();
                                        }}
                                        variant="outlined"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Existing
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={saveNewTable}
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    <Box
                        sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="userName"
                                        required
                                        fullWidth
                                        id="userName"
                                        label="User Name"
                                        onChange={handleChange}
                                        autoFocus
                                        value={userData.userName}
                                        error={errorValue.userName.error}
                                        helperText={errorValue.userName.error && errorValue.userName.errorMessage}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        type='number'
                                        fullWidth
                                        id="maxLimit"
                                        label="Limit"
                                        name="maxLimit"
                                        onChange={handleChange}
                                        value={userData.maxLimit}
                                        autoComplete="family-name"
                                        error={errorValue.maxLimit.error}
                                        helperText={errorValue.maxLimit.error && errorValue.maxLimit.errorMessage}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={1} container alignItems="center" justifyContent="center">
                                    <Tooltip title="Limits are basically number of api calls allowed in every 10 min">
                                        <InfoIcon color="info" />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        onChange={handleChange}
                                        value={userData.email}
                                        error={errorValue.email.error}
                                        helperText={errorValue.email.error && errorValue.email.errorMessage}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {credential.apiKey && (
                                        <TextField
                                            disabled
                                            fullWidth
                                            name="apiKey"
                                            label="Api Key"
                                            type="text"
                                            id="apiKey"
                                            variant="filled"
                                            defaultValue={credential.apiKey}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyToClipboard text={credential.apiKey}>
                                                            <Tooltip title="Copy" arrow>
                                                                <IconButton>
                                                                    <FileCopy />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </CopyToClipboard>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {credential.secretKey && (
                                        <TextField
                                            disabled
                                            fullWidth
                                            name="secretKey"
                                            label="Secret Key"
                                            type="text"
                                            id="secretKey"
                                            variant="filled"
                                            defaultValue={credential.secretKey}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyToClipboard text={credential.secretKey}>
                                                            <Tooltip title="Copy" arrow>
                                                                <IconButton>
                                                                    <FileCopy />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </CopyToClipboard>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Create
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}
